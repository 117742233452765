import React, { useState } from 'react';
import {render} from 'react-dom';
import axios from 'axios';
import { Col, Button, Form, FormGroup, Label, Input, FormText, FormFeedback} from 'reactstrap';

class ContactForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showContactForm: true,
			showContactFormSuccess: false,
			name: '',
			email: '',
			text: '',
			selectedFile: null,
			validate: {
				emailState: '',
				nameState: '',
				messageState: '',
				charLimit: 250,
			},
		};
		this.hideComponent = this.hideComponent.bind(this);
	}
	
	hideComponent(name) {
		console.log(name);
		switch (name) {
			case "showContactForm":
			this.setState({ showContactForm: !this.state.showContactForm});
			break;
			case "showContactFormSuccess":
			this.setState({ showContactFormSuccess: !this.state.showContactFormSuccess});
			break;
			default: return null;
		}
	}

  validateName(event) {
    const nameRex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
    const { validate } = this.state
      if (nameRex.test(event.target.value)) {
        validate.nameState = 'has-success'
      } else {
        validate.nameState = 'has-danger'
      }
      this.setState({ validate })
    }
	
  validateEmail(event) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state
      if (emailRex.test(event.target.value)) {
        validate.emailState = 'has-success'
      } else {
        validate.emailState = 'has-danger'
      }
      this.setState({ validate })
    }
	
  validateText(event) {
    const textRex = /^[A-Za-z0-9åäö!,:; ]+$/;
    const { validate } = this.state
      if (textRex.test(event.target.value) &&
		 event.target.value.length <= this.state.validate.charLimit) {
        validate.textState = 'has-success'
      } else {
        validate.textState = 'has-danger'
      }
      this.setState({ validate })
    }
	
  handleSubmit(event) {
	  event.preventDefault();
	  axios({
		  method: "POST",
		  url: "/send",
		  data: this.state,
          body: JSON.stringify(this.state)
	  }).then((response)=>{
		  if (response.data.status === 'success') {
			  console.log(this.state);
			  this.setState({name: '', email: '', text: '', selectedFile: null});
			  this.hideComponent("showContactForm"); 
			  this.hideComponent("showContactFormSuccess")
		  } else if (response.data.status === 'fail') {
			  alert("Meddelandet kunde ej skickas, försök igen.")
		  }
	  })
  }
	
render () {
	const { showContactForm, showContactFormSuccess, name, email, text, selectedFile } = this.state;
	return(
	<div>
	{showContactForm && (
      <Form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST" encType="multipart/form-data">
        <FormGroup row>
			<Col sm={{size: 4, offset:4}}>
              <Input type="name" 
                     name="name" 
                     id="Name" 
                     placeholder="Namn"
                     value={ name } 
                     valid={ this.state.validate.nameState === 'has-success' }
                     invalid={ this.state.validate.nameState === 'has-danger' }
                     onChange={ (event) => {
                               this.validateName(event)
                               this.setState({name: event.target.value})
                              }}
               />
              <FormFeedback invalid>Fyll i ett riktigt namn</FormFeedback>
              <FormFeedback valid>Schysst namn!</FormFeedback>
           </Col>
        </FormGroup>
        <FormGroup row>
			<Col sm={{size: 4, offset:4}}>
              <Input type="email" 
                     name="email" 
                     id="Email" 
                     placeholder="Email" 
                     value={ email } 
                     valid={ this.state.validate.emailState === 'has-success' }
                     invalid={ this.state.validate.emailState === 'has-danger' }
                     onChange={ (event) => {
                               this.validateEmail(event)
                               this.setState({email: event.target.value})
                              }}
               />
              <FormFeedback invalid>Fyll i en korrekt mailadress</FormFeedback>
              <FormFeedback valid>Ser bra ut!</FormFeedback>
           </Col>
        </FormGroup>
        <FormGroup row>
			<Col sm={{size: 4, offset:4}}>
              <Input type="textarea" 
					   name="text" 
					   id="Text"
					   placeholder="Meddelande"
                       value={ text } 
                       valid={ this.state.validate.textState === 'has-success' }
                       invalid={ this.state.validate.textState === 'has-danger' }
                       onChange={ (event) => {
                                 this.validateText(event)
                                 this.setState({text: event.target.value})
                                }}
              />
              <FormFeedback invalid>För långt eller så har du använt en massa tecken du behöver tecken för att skriva ett meddelande</FormFeedback>
              <FormFeedback valid>Okej!</FormFeedback>
           </Col>
        </FormGroup>
        <FormGroup row>
			<Col sm={{size: 4, offset:4}}>
              <Input type="file" 
                     name="file" 
                     id="File"
                     accept=".jpg, .png, .pdf"
                     onChange={ (event) =>{
							   let reader = new FileReader();
							   reader.onload = (event)=> {
                               this.setState({selectedFile: reader.result})
                                }
							  reader.readAsDataURL(event.target.files[0]);
							  }}
                              />
              <FormFeedback invalid>Filen är för stor eller av ej giltig filtyp</FormFeedback>
              <FormText></FormText>
           </Col>
        </FormGroup>
        <FormGroup check row>
          <Col sm={{ size: 10, offset: 1
				  }}>
		<Button className="transparent">Skicka</Button>
          </Col>
        </FormGroup>
      </Form> 
)}
{showContactFormSuccess && (
<p className="lead">Ditt meddelande är skickat. Vi återkommer inom kort!</p>
)}
</div>
      );
  }
}

export default ContactForm;