import React from 'react';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import logo from './logo.svg';
import ClojureLogo from './Clojure_logo.svg';
import { faUserTie, faCalendar, faMapMarkerAlt, faBriefcase, faFileContract, faMoneyCheckAlt, faUserFriends, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Particles from 'react-particles-js';
import { Col, Row, Jumbotron, Button, Card, CardTitle, CardText, Table } from 'reactstrap';
import ContactForm from './ContactForm';
const FULLPAGE_LICESE_KEY  = process.env.REACT_APP_FULLPAGE_LICENSE_KEY;

const Fullpage = () => (
  <ReactFullpage
    scrollOverflow={true}
	anchors={['firstPage', 'secondPage', 'thirdPage']}
    sectionsColor={["orange", "purple", "green"]}
    licenseKey = {FULLPAGE_LICESE_KEY}
    scrollingSpeed = {1000} /* Options here */s


    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <div className="section">
            <div className="scale-in-center" id="welcomeLogo">
              <img src={logo} className="App-logo" alt="logo" />
            </div>
            <div className="scale-in-center" id="welcomeButton">
              <Button className="transparent" onClick={() => fullpageApi.moveSectionDown()}>Välkommen</Button>
            </div>
		          <Particles id="particles-js"
                    params={{ "particles": {
                      "number": {
                        "value": 75,
                        "density": {
                          "enable": true,
                          "value_area": 800
                        }
                      },
                      "color": {
                        "value": "#ffffff"
                      },
                      "shape": {
                        "type": "circle",
                        "stroke": {
                          "width": 0,
                          "color": "#000000"
                        },
                        "polygon": {
                          "nb_sides": 5
                        },
                        "image": {
                          "src": "img/github.svg",
                          "width": 100,
                          "height": 100
                        }
                      },
                      "opacity": {
                        "value": 0.5,
                        "random": false,
                        "anim": {
                          "enable": false,
                          "speed": 3,
                          "opacity_min": 0.1,
                          "sync": false
                        }
                      },
                      "size": {
                        "value": 3,
                        "random": true,
                        "anim": {
                          "enable": false,
                          "speed": 10,
                          "size_min": 0.1,
                          "sync": false
                        }
                      },
                      "line_linked": {
                        "enable": true,
                        "distance": 150,
                        "color": "#ffffff",
                        "opacity": 0.4,
                        "width": 1
                      },
                      "move": {
                        "enable": true,
                        "speed": 3,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                          "enable": false,
                          "rotateX": 600,
                          "rotateY": 1200
                        }
                      }
                    },
                    "interactivity": {
                      "detect_on": "canvas",
                      "events": {
                        "onhover": {
                          "enable": true,
                          "mode": "grab"
                        },
                        "onclick": {
                          "enable": true,
                          "mode": "push"
                        },
                        "resize": true
                      },
                      "modes": {
                        "grab": {
                          "distance": 140,
                          "line_linked": {
                            "opacity": 1
                          }
                        },
                        "bubble": {
                          "distance": 400,
                          "size": 40,
                          "duration": 2,
                          "opacity": 8,
                          "speed": 3
                        },
                        "repulse": {
                          "distance": 200,
                          "duration": 0.4
                        },
                        "push": {
                          "particles_nb": 4
                        },
                        "remove": {
                          "particles_nb": 2
                        }
                      }
                    },
                    "retina_detect": true}}>

                  </Particles>

          </div>
          <div className="section">
            <div className="slide">
              <h1 className="display-3">Om oss</h1>
            </div>
            <div className="slide" data-anchor="about">
              <h1 className="display-3">KP System</h1>
				<div class="container">
					<div class="row">
                      <div class="col-md-12 col-lg-8">
                        <p class="info-text">KP System startade som ett hobbyföretag i en lada i Rottne för länge, länge sedan. Mycket, om inte nästan allt, har förändrats sedan dess. Det vi har kvar är en stolthet till den lokala anknytningen, ett driv och en vilja att leverera bra produkter och att vi har nöjda kunder. Inte samma kunder som då, men då som nu  nöjda kunder.</p>
						<p class="info-text">
						Nu är vi ett produktbolag som tar fram bra webbtjänster för verksamhetsstyrning, planering och uppföljning.
						</p>
						<p class="info-text">
						Välkommen till en prestigelös organisation med ett stort driv framåt där du som har tankar, idéer och ser möjligher har en stor möjlighet att styra din vardag.
						</p>
                      </div>
                      <div class="col-md-12 col-lg-4 pull-right">
                            <Table size="sm">
                              <thead>
                                <tr class="hidden">
                                  <th>Rubrik</th>
                                  <th>Text</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faCalendar} /></th>
                                  <td>Grundat 1989</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faUserFriends} /></th>
                                  <td>7 anställda</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faMapMarkerAlt} /></th>
                                  <td>Kontor på Norremark, Växjö</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faTachometerAlt} /></th>
                                  <td>Trevlig stämning: 100%</td>
                                </tr>
                              </tbody>
                            </Table>
						</div>
                      </div>
                  </div>
            </div>

            <div className="slide">
              <Jumbotron>
                <h1 className="display-3">Panorama</h1>
                <p className="lead">Kartbaserat verksamhetssystem för teknisk förvaltning på kommuner</p>
                <hr className="my-2" />
                <p>Verksamhetssystem där data kan presenteras i en kartvy och sedan dynamiskt uppdateras med status i en händelsekedja. Effektivt verktyg för styrning och uppföljning av verksamhet som uppkommer på geografiskt spridda platser, som exemepvis grävning i kommunal mark eller markhyra för uteservering.</p>
                <p className="lead">
                  <Button className="transparent" onClick={(e) => {e.preventDefault(); window.open('https://panorama-gis.se', '_blank')}}>Panorama hemsida</Button>
                </p>
              </Jumbotron>
            </div>
            <div className="slide">
              <Jumbotron>
                <h1 className="display-3">KP Kalkyl</h1>
                <p className="lead">Kostnadskalkylering för markanläggning</p>
                <hr className="my-2" />
                <p>KP Kalkyl bygger på en erfarnehetsbaserad databas med uppmätta tidsstudier av en mängd arbetsmoment inom markanläggning. Kombinerat sammanställs dessa till kalkyler genom att lägga till, mängdsätta och redigera.</p>
                <p className="lead">
                  <Button className="transparent" onClick={(e) => {e.preventDefault(); window.open('https://kpfakta.se', '_blank')}}>KP Kalkyl hemsida</Button>
                </p>
              </Jumbotron>
            </div>
          </div>
          <div className="section">
			<div className="slide">
              <h1 className="display-3">Jobba med oss</h1>
            </div>
            <div className="slide">
              <h1 className="display-3">Tjänster</h1>
                    <Jumbotron>
                      <p className="lead">Vi växer och inom de här områdena behöver vi förstärka vårt team just nu.</p>
                        <small>Även om n vinte söker något specifikt i är vi alltid öppna för idéer och möjlighet. Skicka oss gärna ett meddelande ändå så kan vi se vad som går att göra. Du når oss enklast via formuläret på nästa sida.</small>
                      <hr className="my-2" />
                      <div class="row justify-content-around">
						  <div class="card-deck justify-content-around">
                            <div class="card border-light col-sm-12 col-md-4">
                              <img class="card-img-top" src={ClojureLogo} alt="Clojure"/>
                              <div class="card-body">
                                <h5 class="card-title">Utvecklare</h5>
                                <p class="card-text">Arbeta med funktionellt med Clojure, React och Datomic</p>
                                <Button className="transparent" onClick={() => fullpageApi.moveTo(3,3)}>Till jobbet</Button>
                                <p class="card-text"><small class="">Upplagd: Löpande rekrytering</small></p>
								<p class="card-text"><small class="">Sista ansökaningsdag: Tillsvidare</small></p>
                              </div>
                            </div>
                            <div class="card col-sm-12 col-md-4">
                              <FontAwesomeIcon class="card-img-top" icon={faUserTie} />
                              <div class="card-body">
                                <h5 class="card-title">Teknisk säljare</h5>
                                <p class="card-text">Företagssäljare och kundansvarig för webbtjänster</p>
                                <Button className="transparent" onClick={() => fullpageApi.moveTo(3,4)}>Till jobbet</Button>
                                <p class="card-text"><small class="">Upplagd 2022-01-03</small></p>
								<p class="card-text"><small class="">Sista ansökaningsdag: 2022-03-03</small></p>
                              </div>
                            </div>
                          </div>
                      </div>
                    </Jumbotron>
            </div>
            <div className="slide" data-anchor="contact">
              <h1 className="display-3">Kontakta oss</h1>
                <ContactForm class="contact"></ContactForm>
            </div>
            <div className="slide" data-anchor="developer">
              <h1 className="display-3">Utvecklare</h1>
				<div class="container">
					<div class="row">
                      <div class="col-md-12 col-lg-8">
                        <p class="info-text">Vi söker fler utvecklare som vill vara med att göra våra produkter ännu bättre. Vi söker dig som har har arbetat med Clojure/ClojureScript antingen professionellt eller vid sidan om på egna projekt. Som utvecklare hos oss ingår du i ett team av utvecklare som styrs av ett core team. Själva arbetet sker över hela stacken och ofta i nära samarbete med säljorganistationen och input från kunder i ett iterativt arbete fram till slutresultat.</p>
						<p class="info-text">
						Välkommen till en prestigelös organisation med ett stort driv framåt där du som har tankar, idéer och ser möjligher har en stor möjlighet att styra din vardag.
						</p>
                        <Button className="transparent" onClick={() => fullpageApi.moveTo(3,2)}>Kontakta oss</Button>
                      </div>
                      <div class="col-md-12 col-lg-4 pull-right">
                            <Table size="sm">
                              <thead>
                                <tr class="hidden">
                                  <th>Rubrik</th>
                                  <th>Text</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faBriefcase} /></th>
                                  <td>Senior/junior utvecklare</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faFileContract} /></th>
                                  <td>Heltid, tillsvidare</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faMapMarkerAlt} /></th>
                                  <td>Växjö</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faMoneyCheckAlt} /></th>
                                  <td>Fastlön</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faCalendar} /></th>
                                  <td>Löpande rekrytering <small>(Intervjuer sker löpande, tjänsten kan tillsättas innan slutdatum)</small></td>
                                </tr>
                              </tbody>
                            </Table>
						</div>
                      </div>
                  </div>
            </div>
            <div className="slide" data-anchor="sales">
              <h1 className="display-3">Teknisk IT-säljare/projektledare</h1>
				<div class="container">
					<div class="row">
                      <div class="col-md-12 col-lg-8">
                        <p class="info-text">Som säljare hos oss är du en del av ett team. Du står för kontakten ut mot markanden och är ansvarig för att sälja och leverera. Ansvaret är ditt, men vägen dit är gemensam och till din hjälp har du en nära kontakt med utvecklingsavdelningen för att dynamiskt kunna anpassa lösningar och agilt kunna svara upp på kunders krav.</p>
						<p class="info-text">
						Välkommen till en prestigelös organisation med ett stort driv framåt där du som har tankar, idéer och ser möjligher har en stor möjlighet att styra din vardag.
						</p>
                        <Button className="transparent" onClick={() => fullpageApi.moveTo(3,2)}>Kontakta oss</Button>
                      </div>
                      <div class="col-md-12 col-lg-4 pull-right">
                            <Table size="sm">
                              <thead>
                                <tr class="hidden">
                                  <th>Rubrik</th>
                                  <th>Text</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faBriefcase} /></th>
                                  <td>Senior/junior utvecklare</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faFileContract} /></th>
                                  <td>Heltid, tillsvidare</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faMapMarkerAlt} /></th>
                                  <td>Växjö</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faMoneyCheckAlt} /></th>
                                  <td>Fastlön och rörlig försäljningsbonus</td>
                                </tr>
                                <tr>
                                  <th scope="row"><FontAwesomeIcon icon={faCalendar} /></th>
                                  <td>2022-01-03 <small>(Intervjuer sker löpande, tjänsten kan tillsättas innan slutdatum)</small></td>
                                </tr>
                              </tbody>
                            </Table>
						</div>
                      </div>
                  </div>
            </div>

          </div>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default Fullpage;
